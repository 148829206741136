import './App.css';
import ContactForm from './components/ContactForm';
import Header from './components/Header';

function App() {
  return (
    <div className="App">
      <Header />
      <ContactForm />
      <footer className='footer'><p className="footer-text">&#xA9; Copyright 2024 OCN Eats and SpotOn Works for You</p></footer>
    </div>
  );
}

export default App;
