import ocnImg from '../assets/ocn-eats.png';
import spotImg from '../assets/spotonlogo.PNG';
import './Header.css';

export default function Header() {
    return (
        <header className="header">
            <nav class="navbar">
                <ul>
                    <li><a href="http://ocneats.com">OCN Eats</a></li>
                    <li><a href="http://spoton.com">SpotOn</a></li>
                </ul>
            </nav>
            <div className="imgwrapper">
                <div className="imgdiv"><img className="ologo" src={ocnImg}/></div>
                <div className="imgdiv"><p className="sep">|</p></div>
                <div className="imgdiv"><img className="slogo" src={spotImg}/></div>
            </div>
          
            <div className="discover">
            <h3>Discover How OCN Eats and SpotOn Works For You</h3>
            </div>
        </header>
    )
}