import { useState } from 'react';
import './ContactForm.css';

export default function ContactForm() {
     
      const [submitted, isSubmitted ] = useState(false);

      const [formData, setFormData] = useState({
        name: '',
        businessName: '',
        businessAddress: '',
        phone: '',
        email: '',
        message: '',
      });

      const [errors, setErrors] = useState({});

      const handleChange = (e) => {
        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      };

      const handleSubmit = async(e) => {
        e.preventDefault();

        const newErrors = validateForm(formData);
        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0){
          const response = await fetch("https://robbievancamp.com/api/contact", {
            method: "POST",
            headers: {
              "Content-type": "application/json",
            },
            body: JSON.stringify({formData}),
          }).then((res) => res.json())
          .then(() => {
            isSubmitted(true);
          }).catch((err) => {
            alert(err);
          })
        };
      };

      const validateForm = (data) => {
        const errors = {};

        if (!data.name.trim()){
          errors.name = "Name is required";
        }
        if (!data.businessName.trim()){
          errors.businessName = "Business Name is required";
        }
        if (!data.businessAddress.trim()){
          errors.businessAddress = "Business Address is required";
        }
        if (!data.email.trim()) {
          errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(data.email)) {
          errors.email = 'Email is invalid';
        }
        if (!data.phone.trim()){
          errors.phone = "Business Name is required";
        }
        return errors;
      };

      return (
        <>

{ !submitted && 
        <div className="form-container">
        <h3 className="form-h3">Contact Us Today for a Demo!</h3>
       
        <form className="contact-form" onSubmit={handleSubmit}>
          <label>Name: {errors.name && (<span className="error-message">{errors.name}</span>)}
          <input 
            type="text"
            name="name"
            value={formData.name}
            className = {errors.name ? 'error-border' : ''}
            onChange={handleChange}  
          />
          </label>
          <label>Business Name: {errors.businessName && (<span className="error-message">{errors.businessName}</span>)}
          <input 
            type="text"
            name="businessName"
            value={formData.businessName}
            className = {errors.businessName ? 'error-border' : ''}
            onChange={handleChange}  
          />
          </label>
          <label>Business Address: {errors.businessAddress && (<span className="error-message">{errors.businessAddress}</span>)}
          <input 
            type="text"
            name="businessAddress"
            value={formData.businessAddress}
            className = {errors.businessAddress ? 'error-border' : ''}
            onChange={handleChange}  
          />
          </label>
          <label>Email: {errors.email && (<span className="error-message">{errors.email}</span>)}
          <input 
            type="email"
            name="email"
            value={formData.email}
            className = {errors.email ? 'error-border' : ''}
            onChange={handleChange}  
          />
          </label>
          <label>Phone: {errors.phone && (<span className="error-message">{errors.phone}</span>)}
          <input 
            type="text"
            name="phone"
            value={formData.phone}
            className = {errors.phone ? 'error-border' : ''}
            onChange={handleChange}  
          />
          </label>
         <label>Comments:
          <textarea 
            rows="3"
            name="message"
            value={formData.message}
            onChange={handleChange}  
          ></textarea>
          </label>
          <button className="submit" type="submit">Submit</button>
        </form>
        </div>
        }
        { submitted &&
        <div className="form-container">
            <div><h3 className="form-h3">Thank you for contacting us, {formData.name}.</h3></div>
            <div><h3 className="form-h3"> We will contact you soon on how we can help {formData.businessName}!</h3></div>
        </div>
        }
        </>
      )
    }
